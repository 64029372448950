<template>
  <div>
    <!-- Filters -->
    <packages-list-filter :name-filter.sync="nameFilter" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
           <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'general-settings-subscriptionPackage-create' }"
              >
                <span class="text-nowrap">Add Package</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPackageListTable"
        class="position-relative"
        :items="fetchPackages"
        responsive
        :fields="
          $route.name === 'general-settings-subscriptionPackage-list'
            ? tableColumns
            : catalogueTableColumns
        "
        primary-key="packageId"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: '', params: { id: data.item.packageId } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link class="font-weight-bold d-block text-nowrap">
              <span class="text-capitalize">{{ data.item.name }}</span>
            </b-link>
          </b-media>
        </template>

        <!-- Column: description -->
        <template #cell(description)="data">
          <span title="data.item.description" class="font-weight-bold d-block desp-width">
            {{ data.item.description }}
          </span>
        </template>

        <!-- Column:withHardware -->
        <template #cell(withHardware)="data">
          {{ data.item.withHardware === true ? 'Yes' : 'No' }}
        </template>

        <!-- Column:branchesLimit -->
        <template #cell(branchesLimit)="data">
          {{ data.item.branchesLimit }}
        </template>

        <!-- Column:cashiersPerBranchLimit -->
        <template #cell(cashiersPerBranchLimit)="data">
          {{ data.item.cashiersPerBranchLimit }}
        </template>
        <!-- Column: Show Details -->
        <template #cell(show_details)="row">
          <b-button
            v-ripple.400="'rgba(53, 197, 240, 0.15))'"
            variant="flat-info"
            class="btn-icon rounded-circle"
            size="sm"
            @click="row.toggleDetails"
          >
            <feather-icon
              :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </b-button>
        </template>

        <!-- Column: Row Details -->
        <template #row-details="data">
          <subscription-list
            :subscriptions="data.item.subscriptions"
            @refetch-data="refetchData"
          />
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import { avatarText } from "@core/utils/filter";
import store from "@/store";
import usePackageList from "./usePackageList";
import subscriptionPackageStore from "../subscriptionPackageStore";
import PackagesListFilter from "./PackagesListFilter.vue";
import SubscriptionList from "../subscription-list/SubscriptionList.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BLink,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,

    //  Local Components
    SubscriptionList,
    PackagesListFilter,

  },
  directives: {
    Ripple,
  },
  setup() {
    const PACKAGE_APP_STORE_MODULE_NAME = "app-package";

    // Register module
    if (!store.hasModule(PACKAGE_APP_STORE_MODULE_NAME))
      store.registerModule(
        PACKAGE_APP_STORE_MODULE_NAME,
        subscriptionPackageStore
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PACKAGE_APP_STORE_MODULE_NAME))
        store.unregisterModule(PACKAGE_APP_STORE_MODULE_NAME);
    });

    const {
      fetchPackages,
      tableColumns,
      catalogueTableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPackageListTable,
      refetchData,

      //  Filters
      nameFilter,

      
    } = usePackageList();

    return {
      fetchPackages,
      tableColumns,
      catalogueTableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPackageListTable,
      refetchData,

      //   Filters
      nameFilter,
      // UI
      avatarText
    };
  },
};
</script>

<style lang="scss" scoped>
.desp-width {
  width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
