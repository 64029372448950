import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function usePackageList() {
  // Use toast
  const toast = useToast()

  const refPackageListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions', sortable: false },
    { key: 'name', sortable: false },
    { key: 'description', sortable: false },
    { key: 'withHardware', sortable: false, label: 'With Hardware' },
    { key: 'branchesLimit', sortable: false, label: 'Branches Limit' },
    { key: 'cashiersPerBranchLimit', sortable: false, label: 'Cashier Per Branch Limit' },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]
  const catalogueTableColumns = tableColumns.slice(1)
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('packageId')
  const isSortDirDesc = ref(true)
  const nameFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPackageListTable.value ? refPackageListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refPackageListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, nameFilter], () => {
    refetchData()
  })

  // Call System Codes
  useSystemCodes()

  const fetchPackages = (ctx, callback) => {
    store
      .dispatch('app-package/fetchPackages', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        name: nameFilter.value,
      })
      .then(response => {
        const records = response.data.data

        callback(records)
        totalRecords.value = response.data.data[0].total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Packages list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchPackages,
    tableColumns,
    catalogueTableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPackageListTable,
    refetchData,

    //  Filters
    nameFilter,
  }
}
